import axios from 'axios'


const Http = axios.create({
    baseURL: process.env.VUE_APP_API_BASEURL
})

Http.interceptors.request.use((config) => {
    const access_token = localStorage.getItem('access_token')

    config.headers = {
        ...config.headers, 'SCAPI-KEY': `${process.env.VUE_APP_API_KEY}`,
    };
    if(access_token){
        config.headers = {
            ...config.headers, Authorization: `Bearer ${access_token}`,
        };
    }
    return config
});

Http.interceptors.response.use(
    (res) => {
        //console.log(res);
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/auth/login" && err.response) {
            //console.log('token expired');
            // Access Token was expired
            //originalConfig._retry = true;
            if (err.response.status === 401 && err.response.data.error=='Invalid Token Provided') {
                //console.log('token expired');
                //originalConfig._retry = true;
                const refreshToken = localStorage.getItem('refresh_token')

                try {
                    await Http.post("/auth/refresh", {refresh_token: refreshToken})
                    .then((response) => {
                        //console.log('renew');
                        localStorage.setItem("access_token", response.data.tokens.access_token); 
                        localStorage.setItem("expire", response.data.tokens.expire);
                        return Http(originalConfig);
                    })
                    .catch(() => {
                        localStorage.clear();
                        window.location.href = '/account/login';
                        //this.$router.push({path: "/account/login"});
                    });
                    return Http(originalConfig);
                } catch (_error) {
                    localStorage.clear();
                    window.location.href = '/account/login';
                    //this.$router.push({path: "/account/login"});
                    //return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }

)

export default Http